import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            // Navigation
            'accueil': 'EIB',
            "lecole": 'School',
            'nos-valeurs': 'Our values',
            'le-bilinguisme': 'Bilingualism',
            'vie-scolaire': 'School life',
            'les-classes': 'The classes',
            'maternelle': 'Kindergarten',
            'primaire': 'Primary',
            'cambridge': 'Cambridge',
            'inscription': 'Enrollment',
            'mini-club': 'Mini club',
            'contact': 'Contact',
            'parents': 'Parents',
            // ----- End Navigation

            // Top Header
            'visite-perso': 'Personalized visit',
            'renseignez-vous': 'Inquire on',
            // ----- Top Header

            // LE BILINGUISME
            'biling-title': 'Bilingualism',
            'biling-text1': 'The success of our students also depends on their capacity to communicate in both languages. Our role and mission at EIB is also to permit students to come into contact with and to respect other cultures.',
            'biling-text2': 'The French academic program is reinforced and the academic level of the school is high. The number of hours of instruction in French, mathematics and history are identical to those of French schools. The English academic program follows the curriculum proposed by Anglophone educational establishments and is based on the same instructional materials. All English classrooms, from Preschool (starting at age 2) to primary, are equipped with an interactive whiteboard.',
            'biling-text3': 'In a bilingual environment, students learn English and French in a natural way, by way of immersion with certified Anglophone and French teachers. <br />The linguistic immersion program is organised for children as young as two years old. English is therefore practiced on a daily basis and groups of different levels allow the teaching to be adapted for each student.',
            'biling-text4': 'Anglophone children / Francophone children',
            'biling-text5': 'Children who do not speak French or English are accepted. We offer individualised and academic upgrading courses, helping the integration of Anglophone students and French students who have never studied French or English while respecting the natural pace of each child.',
            'biling-text6': 'EIB is ',
            'biling-text7': 'a Cambridge ESOL exam centre ',
            'biling-text8': 'and prepares for the following certificates: Starters - Movers - Flyers.',

            // PRIMAIRE
            'primaire-title': 'The primary',
            'primaire-text1': 'As in our Preschool classes, class sizes are limited in order to permit indivividualised support and group work. A new pedagogical tool : all English classes are equiped with an interactive whiteboard to enable fun and interactive lessons from preschool classes through to the Primary classes.',
            'primaire-text2': 'Cultural project 2022/2023',
            'primaire-text3': 'Architecture',
            'primaire-text4': 'French syllabus',
            'primaire-text5': 'Reading skills, comprehension',
            'primaire-text6': 'Writing',
            'primaire-text7': 'Grammar',
            'primaire-text8': 'Conjugation',
            'primaire-text9': 'Vocabulary',
            'primaire-text10': 'Lexical spelling',
            'primaire-text11': 'Grammatical spelling',
            'primaire-text12': 'Written expression',
            'primaire-text13': 'Mathematics : reasoning and algebra',
            'primaire-text14': 'Mathematical operations',
            'primaire-text15': 'Geometry',
            'primaire-text16': 'English syllabus',
            'primaire-text17': 'Reading',
            'primaire-text18': 'Grammar',
            'primaire-text19': 'Spelling',
            'primaire-text20': 'Oral expression',
            'primaire-text21': 'Written expression',
            'primaire-text22': 'Civics-personal development',
            'primaire-text23': 'Science',
            'primaire-text24': 'Geography',
            'primaire-text25': 'Art',
            'primaire-text26': 'Music : piano or violon',
            'primaire-text27': 'Music education is provided by our Italian music teacher with a weekly group session on music theory and singing followed by one-on-one piano lessons.',
            'primaire-text28': 'Sport',
            'primaire-text29': 'Our sports teacher delivers activities every week : Gymnastics, Athletics ... Swimming classes take place in September and in May and June.',
            'primaire-text30': 'Information communication technology',
            'primaire-text31': 'At EIB, ICT is used a teaching tool, which permits students to conduct reseach and do excercises related to their syllabus.',
            'primaire-text32': 'Report cards are given at the end of each trimester.',
            'primaire-text33': 'Citizens booklet : health / security / pedestrian licence',
            'primaire-text34': 'This enables each child to develop his or her sense of rights and duties, responsabilities and liberties.',
            'primaire-text35': 'School times',
            'primaire-text36': 'Monday - Tuesday - Thursday - Friday',
            'primaire-text37': 'CP : from 8:30am to 12:30am and from 2pm to 4:50pm',
            'primaire-text38': 'CE1 – CE2 – CM1 – CM2 : from 8:30am to 12:30am and from 2pm to 4:50pm',
            'primaire-text39': 'After school study period',
            'primaire-text40': 'After school study is held every evening except Wednesdays and the last day before the school holidays.',
            'primaire-text41': 'CP - CE1 : from 5pm to 5:45pm',
            'primaire-text42': 'CE2 - CM1- CM2 : from 5:10pm to 6:00pm',
            'primaire-text43': 'Primary enrolment',
            'primaire-text44': 'For all enrolments, a school record is required.<br />Entrance exams may be organised at the principal\'s request.<br />The principal\'s decision will be communicated to the parents.<br />',
            'primaire-text45': 'Download the enrolment document here',


            // CONTACT
            'contact-error-form': 'Please fill in all required fields',
            'contact-text': "Le Pain de Sucre is a private educational establishment <strong>declared and approved by the National Education since 1982.</strong>",
            'contact-prise-rdv': 'Make an appointment by phone at',
            'contact-par-mail': ' or by mail to ',
            'contact-plaholder1': 'Last name *',
            'contact-plaholder2': 'First name *',
            'contact-plaholder3': 'Phone *',
            'contact-plaholder4': 'Mail *',
            'contact-plaholder5': 'Select an answer *',
            'contact-plaholder6': 'Registration of your child in kindergarten',
            'contact-plaholder7': 'Inscription de votre enfant en primaire',
            'contact-plaholder8': 'Registration of your child in primary',
            'contact-plaholder9': 'Request an appointment',
            'contact-plaholder10': 'Other',
            'contact-plaholder11': 'Tell us more ... *',
            'contact-policies': 'I accept the ',
            'contact-policies1': 'site privacy policy *',
            'contact-send': 'Submit the form',
            'contact-direction': 'The management receives Mondays, Tuesdays and Thursdays only in the afternoon, by appointment.f',

            // MATERNELLE
            'maternelle-title': 'Preschool-Kindergarten',
            'maternelle-text1': '<p>We know that arriving at a new school is an important time for every child and their parents. From the moment of registration, we do everything we can for a successful integration, adapted to each child.</p> <p>The teaching team is made up of teachers of different nationalities, graduates and all with serious teaching experience, who maintain an open and positive relationship with families.</p>',
            'maternelle-text2': 'Let\'s make an appointment',
            'maternelle-text3': 'Classroom Cultural Project 2022/2023',
            'maternelle-text4': 'Art',
            'maternelle-text5': 'The toddlers (from the age of 2) are able to discover the world of sharing, playing, and growing together.<br /><br />Their classrooom is colourful and organised around play centres and workshops and of course musical and motor skill activities.<br /><br />Dummies, soft toys and nappies are welcome.',
            'maternelle-text6': 'Baby Éveil - Classes Maternelles',
            'maternelle-text7': '<p>The classrooms are cheerful and colourful with play corners and a variety of specialised materials, adapted for an active pedagogy, permitting children to learn while playing.</p> <p>The timetable is organised in the form of revolving workshops which allow the children to learn how to work independently.<br />Before each holiday, the parents receive their child\'s work folder.<br />Assessments take place each trimester.</p>',
            'maternelle-text8': 'Preschool',
            'maternelle-text9': 'Vocabulary and language',
            'maternelle-text10': 'Development of pre-writing skills',
            'maternelle-text11': 'Pre-mathematics-logic',
            'maternelle-text12': 'Sensorial activities',
            'maternelle-text13': 'Art and music',
            'maternelle-text14': 'Development of gross motor skills',
            'maternelle-text15': 'Pre-Kindergarten-Kindergarten',
            'maternelle-text16': 'Early stage reading and writing',
            'maternelle-text17': 'Mathematics',
            'maternelle-text18': 'Technology and science',
            'maternelle-text19': 'Art and music',
            'maternelle-text20': 'Sports (gymnastics...)',
            'maternelle-text21': 'Discover the world',
            'maternelle-text22': 'School times',
            'maternelle-text23': 'Pre-Kindergarten-Kindergarten',
            'maternelle-text24': 'Monday - Tuesday - Thursday - Friday',
            'maternelle-text25': 'TPS : Les lapins (2 ans) - 9:00 to 11:20am and from 2:00 to 4:30pm',
            'maternelle-text26': 'PS : Les écureuils (3 ans) - 9:00 to 11:20am and from 2:00 to 4:30pm',
            'maternelle-text27': 'MS : Les pandas (4 ans) - 9:00 to 12:00am and from 1:30 to 4:30pm',
            'maternelle-text28': 'GS : Les koalas (5 ans) - 9:00 to 12:00am and from 1:30 to 4:30pm',
            'maternelle-text29': 'Preschool-Kindergarten enrolment',
            'maternelle-text30': 'For all enrolments a school record is requested.<br />Entrance exams may be organised upon the principal\'s request.<br />The principal\'s decision will then be communicated to the parents.<br />',
            'maternelle-text31': 'Download the enrolment document here',
            'maternelle-text32': 'Early learning in Music and Sport',
            'maternelle-text33': '<p>The little ones have daily motor skills and body awareness activities. Beginning in the middle-nursery section, a sports teacher delivers team sport and gymnastic classes.</p> <p><strong>Music</strong></p> <p>2/3 years : With their English teacher, children have daily percussion sessions. They also have an introduction to music with the Italian teacher.<br /> 4/5 years : Our music teacher delivers singing, music and piano classes in Italian.</p>',



            // MINI-CLUB
            'mini-club-title': 'The Mini-Club - Happyland',
            'mini-club-text1': 'Playful Wednesdays and holidays for Pre-schoolers and Primary children !',
            'mini-club-text2': 'Educational project and real values',
            'mini-club-text3': 'Through a wide range of playful activities children evolve in an international environment based on respect and tolerance of different cultures. In a homely atmosphere, our pedagogical team caters for all the children need and their wellbeing.',
            'mini-club-text4': 'Activities',
            'mini-club-text5': '<strong>Wednesday\'s Baby-Club and Mini-Club :</strong> A different theme is proposed each month with various art and craft activities, games, stories, songs, cooking, outings, field trips...<br /><br /> <strong>Baby-club and Mini-club during the holidays :</strong> Baby-club and mini-club are open throughout the school holidays (except for one week in December). A different theme is proposed each week with various art and craft activities, games, stories, songs, cooking, outings, field trips...',
            'mini-club-text6': 'Meals',
            'mini-club-text7': 'Our chef provides heathy and balanced meals. Snacks are given in the morning for the Primary classes and in the afternoon for Pre-schoolers and Primary classes.',
            'mini-club-text8': 'Mini-Club Program - Happyland',
            'mini-club-text9': 'Download the programs',

            // INSCRIPTION
            'inscription-title': 'Information - enrolment',
            'inscription-text1': 'EIB Cagnes offers guided tours from Monday to Friday by appointment only.',
            'inscription-text2': 'You wish to enroll your child in kindergarten, or in primary class within our private educational establishment declared and approved by the National Education since 1982.',
            'inscription-text3': 'Application pack Preschool and Primary',
            'inscription-text4': 'Registrations at our school take place in the month of January preceding the start of the school year. Registrations during the year are possible, subject to availability.',
            'inscription-text5': 'Download the registration file',
            'inscription-text6': 'Download the registration file',
            'inscription-text7': 'To enrol your child please contact the school for an appointement.',
            'inscription-text8': 'School year 2021/2022 :Enrolments during the school year are accepted provided there are vacancies.',
            'inscription-text9': 'School year 2022/2023 : From January',
            'inscription-text10': 'In order to complete the enrolment you should return the Enrolment File and the following documents :',
            'inscription-text11': 'Family book or Passport',
            'inscription-text12': 'Health record booklet (vaccinations)',
            'inscription-text13': 'School record for Primary classes',
            'inscription-text14': 'Passport size photos (2)',
            'inscription-text15': 'Copies of the IDs of people authorized to pick up the child',
            'inscription-text16': 'Payment of the registration fees',
            'inscription-text17': 'Bank details and direct debit authorisation',
            'inscription-text18': 'Justification of guardianship or joint custody',
            'inscription-text19': 'At the beginning of the school year :',
            'inscription-text20': 'Civil liability + physical damage insurance',
            'inscription-text21': 'Medical certificate stating the student\'s physical fitness to do sports',
            'inscription-text22': 'The school principal welcomes you every day by appointment',
            'inscription-text23': 'You can make an appointement by phone 04 93 09 65 56',

            // CAMBRIDGE
            'cambridge-title': 'Cambridge examination centre ',
            'cambridge-text1': 'The EIB School Le Pain de Sucre is a Cambridge examination centre that prepares the following diplomas : ',
            'cambridge-text2': 'Cambridge English is the world leader in the assessment and certification of the English language',
            'cambridge-text3': 'The Cambridge English certificates are recogised worldwide. There are 6 levels of certificate which correspond to the European Common References for Languages. The exams assess the 4 levels of linguistic skills : oral comprehension, oral expression, written comprehension and expression. These exams assess the candidate\'s ability to use the different linguistic structures in a real situation.',
            'cambridge-text4': 'The Importance of the Cambridge Certificate',
            'cambridge-text5': 'Each year more than 3 million people throughout the world take an exam organised by Cambridge ESOL. Their objective in taking a Cambridge exam, which is recognised worldwide, is to certify their level in English, their linguistic ability which helps them in their studies or their job. The Cambridge exams are accepted in a number of schools, universities and companies.',
            'cambridge-text6': 'Who can take the exams ?',
            'cambridge-text7': 'These exams are recongnised internationally and are required for non-Anglophones who wish to enrol in a university in Great Britain. Furthermore these exams are valued by a majority of employers as a reference.',
            'cambridge-text8': 'Period of validity',
            'cambridge-text9': 'All Cambridge exams are valid for life.',
            'cambridge-text10': 'Desciption of the exam',
            'cambridge-text11': 'The format is the same for all the exams. However the level of difficulty and language proficiency differ in each exam. Each exam is divided into 5 parts : oral comprehension, oral expression, written comprehension and expression and English proficiency. The oral exam is a conversation with a teacher.',

            // VIE SCOLAIRE
            'vie-scolaire-title': 'School life',
            'vie-scolaire-t1': 'The school year is from September to the beginning of July.',
            'vie-scolaire-t3': 'SCHOOL OPENING HOURS',
            'vie-scolaire-t4': 'From Monday to Friday from 7:30am to 6:30pm (also during the school holidays) with the exception of the period form 25 December to the beginning of January and during the summer holidays.',
            'vie-scolaire-t5': 'CLASS TIMES',
            'vie-scolaire-t6': 'Preschool / Kindergarten classes',
            'vie-scolaire-t7': 'Monday - Tuesday - Thursday - Friday',
            'vie-scolaire-t8bis': 'TPS : Les lapins - 9:00 to 11:20 and from 2:00 to 4:20 pm',
            'vie-scolaire-t9': 'PS : Les écureuils (from 2 years) - 9:00 to 11:15 and from 2:00 to 4:20pm',
            'vie-scolaire-t10': 'MS : Les pandas (4 ans) - 8:45 to 12:00 and from 2:00 to 4:30pm',
            'vie-scolaire-t11': 'GS : Les koalas (5 ans) - 8:45 to 12:00 and from 2:00 to 4:30pm',
            'vie-scolaire-t12': 'Naptime is available according to the needs of each child.',
            'vie-scolaire-t13': 'Primary classes',
            'vie-scolaire-t14': 'Monday - Tuesday - Thursday - Friday',
            'vie-scolaire-t15': 'CP: 8:30 to 12:15 and from 1:45 to 4:40 pm',
            'vie-scolaire-t16': 'CE1 – CE2 – CM1 – CM2 : 8:30 to 12:30 and from 1:50 to 4:50 pm',
            'vie-scolaire-t17': 'SCHOOL MEALS',
            'vie-scolaire-t18': '4 separate lunch sittings are provided.<br /><br />Snacks are provided by the school in the morning (primary only: CE2-CM1-CM2) and in the afternoon (all classes).<br /><br />Our menus are posted every week and are available on line on our website under "info".<br /><br />Our chef provides healthy and varied meals and we endeavour to integrate fresh local products.<br /><br />Special diets can be catered for with a medical certificate.',
            'vie-scolaire-t19': 'AFTER SCHOOL STUDY PERIOD',
            'vie-scolaire-t20': 'After school study takes place every evening with the exception of Wednesdays and the last day before the holidays.',
            'vie-scolaire-t21': 'CP - CE1 : from 5:10 to 5:40pm',
            'vie-scolaire-t22': 'CE2 - CM1- CM2 : from 5:10 to 6:00pm',
            'vie-scolaire-t27': 'RECEPTION OUTSIDE CLASS TIMES',
            'vie-scolaire-t23': 'Preschool-Kindergarten : Every day from 7:30 to 9am and from 4:30 to 6:30pm.',
            'vie-scolaire-t24': 'Primary : Every day from 7:30 to 8:30am and after study period from 5:45 to 6:30pm (CP) and from 6:00 to 6:30pm (CE1-CE2-CM1-CM2)',
            'vie-scolaire-t25': 'BABY CLUB - MINI CLUB - HAPPYLAND',
            'vie-scolaire-t26': 'The Baby club and Mini club provide activities and outings on Wednesdays and during the school holidays.<br/>Activities : computer workshop, arts and crafts, games and according to age : outings, theatre, judo, karate, gymnastique récréative...<br/>Every school holiday (with the exception of the Christmas break) the activities of the clubs are organised around a various themes.',
            'vie-scolaire-t30': 'SCHOOL BUS',
            'vie-scolaire-t31': 'The school has its own bus which enables the teachers to organise outings. We also provide a bus service to and from school (mornings and evenings).',
            'vie-scolaire-t32': 'SECURITY',
            'vie-scolaire-t33': 'Security is a high priority at our school. The school is covered by video cameras.<br />No adult is allowed to enter the school without an appointement (or for a meeting or a celebration) and without a badge.<br />Every parent will be given a badge at the biginning of the school year.',


            // NOS VALEURS
            'nos-valeurs-title': 'Our values',
            'nos-valeurs-text1': 'Our teaching team is composed of qualified and very motivated teachers from different nationalities. We offer an educational program focused on the following principles:',
            'nos-valeurs-list1': 'A bilingual and multidisciplinary curriculum in accordance with the French and the Anglophone national education systems',
            'nos-valeurs-list2': 'An individualised and active pedagogy tailored to the students’ needs and based on his or her autonomy',
            'nos-valeurs-list3': 'Motor skill and sport activities (baby gym, gross motor skill development, swimming, etc.)"',
            'nos-valeurs-list4': 'Musical-percussion initiation and, from age four, piano taught in Italian',
            'nos-valeurs-list5': 'ICT and use of interactive whiteboards',
            'nos-valeurs-list6': 'Educational field trips, school outings',
            'nos-valeurs-list7': 'Extracurricular activities (options: Chinese, chess club, choir in Italian, Italian, etc.)',
            'nos-valeurs-list8': 'Opening hours from 7:30am-6:30pm and on Wednesdays and during school holidays (baby-club/mini-club)',
            'nos-valeurs-list9': 'A healthy and secure environment',
            'nos-valeurs-text2': 'The pedagogy of INTERNATIONAL BILINGUAL SCHOOLS is based on the following principles :',
            'nos-valeurs-text3': `<strong>DEVELOPING LEARNING SKILLS</strong><br />
                Conducting research in an intelligent and creative manner.<br />
                Developing critical thinking skills. Learning to make decisions wisely after reflection, enabling students to solve complex problems.
                <br />
                Communicating in more than one language and learning to respect the values and cultures of other students.
                <br />
                Taking “risks” by tackling new situations without apprehension.
                <br />
                We encourage our students to think, to make decisions and to use all the means given to them to increase their knowledge.`,
            'nos-valeurs-text4': 'Develop the following know-being and qualities :',
            'nos-valeurs-list10': 'Tolerance',
            'nos-valeurs-list11': 'Self-respect and respect for others',
            'nos-valeurs-list12': 'Empathy',
            'nos-valeurs-list13': 'Cooperation',
            'nos-valeurs-list14': 'Integrity',
            'nos-valeurs-list15': 'Responsibility',
            'nos-valeurs-list16': 'Self-confidence',
            'nos-valeurs-list17': 'Curiosity',
            'nos-valeurs-list18': 'Enthusiasm',
            'nos-valeurs-list19': 'Autonomy',

            // ECOLE
            'ecole-text': `Our school welcomes students of all nationalities and uses an innovative teaching approach, offering a genuine bilingual education : <strong>50% English, 50% French</strong> from the age of 2 upwards.
                <br /><br />
                We offer education for all levels of <strong>preschool (starting at age of 2), as well as primary school from kindergarten to grade five.</strong>
                <br /><br />
                In a climate of open dialogue, respect, and trust, each child grows academically and socially. We prepare each child to live and act responsibly in society by providing them with the fundamental tools and the freedom to explore and create. We respect each child's capabilities, maturity, biological rhythm, family and social environment.`,
            // INDEX
            'home-top-title': 'Give your children an international future',
            'home-top-subtitle': 'Bilingual International School 50% French / 50% English',
            'home-text-1': `<span>Following the model</span> of our EIB schools of Nice and Pégomas, <span>our school welcomes students from all nationalities in classes of limited size.</span> We offer a new insight into teaching by providing <span>a real bilingual environment : 50% English, 50% French</span> from the age of 2 upwards.`,
            "earth-1": "Bilingual school since 1982",
            "earth-2": "Language immersion from 2 years old",
            "earth-3": "Opening on the world",
            "earth-4": "An awakening to culture",
            "earth-5": "Sports, musical and artistic activities",
            'eib-difference': 'The EIB Difference',
            'eib-diff-text': "In a climate of open dialogue, of respect, and trust, each child grows scholastically and socially. We prepare each child to live and act responsibly in society by providing them with the fundamental tools and the freedom to explore and create. We respect each child's capabilities, maturity, biological rhythm and the family and social environment.",
            'eib-mos-1': 'Bilingual education since 1982',
            'eib-mos-2': 'Opening on the world',
            'eib-mos-3': 'Active and rigorous pedagogy',
            'eib-mos-4': 'Dialogue, respect and trust',
            'eib-mos-5': 'Cultural, artistic and sporting activities',
            'eib-mos-6': 'Baby Club from 2 years old, Kindergarten and primary',
            'directrice-title': 'A word from the school\'s founder and Principal',
            'directrice-text1': 'Our International Schools welcome 950 students :',
            'directrice-text2': 'For 35 years ou International schools have been offering a high quality bilingual education. Our bilingual and multidisciplinary curriculum enables us to follow the French and English national education systems. Our teaching team is composed of qualified and highly motivated teachers of different nationalities.',
            'directrice-text3': 'At EIB each child is encouraged to express and participate actively through a multidisciplinary education and scholastic, cultural, artistic and sportive activities. In a climate of open dialogue and respect we invite our students to develop critical thinking skills, to fix clear objectives and to gain confidence in an international motivating environment. At EIB we prepare our students for the world of tomorrow by giving them the tools they need to grow respecting the values of different cultures.',


            // TOP HEADER IMAGE
            'top-header-image1': "EIB Cagnes Le Pain de Sucre - THE SCHOOL OF SUCCESS",
            'top-header-image2': 'THE PEGADOGY OF OUR INTERNATIONAL BILINGUAL SCHOOL, IN CAGNES-SUR-MER',
            'top-header-image3': '50% FRENCH- 50% ENGLISH BILINGUAL EDUCATION',
            'top-header-image4': 'DISCOVER EIB KINDERGARTEN IN CAGNES-SUR-MER',
            'top-header-image5': 'DISCOVER EIB PRIMARY SCHOOL IN CAGNES-SUR-MER',
            'top-header-image6': 'SIGN UP FOR OUR CAMBRIDGE ENGLISH EXAM CENTER IN CAGNES-SUR-MER',
            'top-header-image7': 'Give your children an international future!',
            'top-header-image8': 'SIGN UP FOR OUR CAMBRIDGE ENGLISH EXAM CENTER IN CAGNES-SUR-MER',
            'top-header-image9': 'BABY-CLUB / MINI-CLUB / HAPPYLAND',
            'top-header-image10': 'COMING TO EIB CAGNES LE PAIN DE SUCRE',
            'top-header-image11': 'PROTECTION OF PRIVATE LIFE',
            'top-header-image12': 'SCHOOL REGULATIONS / SCHOOL LIFE',

            // Footer
            'footer-une-question': 'A question ?',
            'footer-notre-ecole-en-video': 'Our school in video',
            'footer-telecharger-dossier': 'Download the registration file',
            'footer-icon-text1': 'Quality education',
            'footer-icon-text2': 'A strong school program',
            'footer-icon-text3': 'A school turned towards the world',
            'footer-icon-text4': 'Courses in both languages',
            'footer-demande-renseignement': 'Ask for information',
            'footer-actu-facebook': 'Find news from our school on Facebook',
            'footer-suivi-insta': 'Follow us on instagram',
            'footer-ecole-maternelle': 'Kindergarten',
            'footer-ecole-primaire': 'Primary school',
            'footer-dossier-inscription': 'Registration file',
            'footer-protection': 'Protection of private life',



        }
    },
    fr: {
        translation: {
            // Navigation
            'accueil': 'EIB',
            "lecole": "L'école",
            'nos-valeurs': 'Nos valeurs',
            'le-bilinguisme': 'Le bilinguisme',
            'vie-scolaire': 'Vie scolaire',
            'les-classes': 'Les classes',
            'maternelle': 'Maternelle',
            'primaire': 'Primaire',
            'cambridge': 'Cambridge',
            'inscription': 'Inscription',
            'mini-club': 'Mini-club',
            'contact': 'Contact',
            'parents': 'Parents',
            // ----- End Navigation

            // Top Header
            'visite-perso': 'Visite personnalisée',
            'renseignez-vous': 'Renseignez-vous au',
            // ----- End Top Header


            // TOP HEADER IMAGE
            'top-header-image1': 'NOTRE ÉCOLE BILINGUE LE PAIN DE SUCRE À CAGNES-SUR-MER',
            'top-header-image2': 'LA PÉGADOGIE DE NOTRE ÉCOLE INTERNATIONALE BILINGUE,  À CAGNES-SUR-MER',
            'top-header-image3': 'UN ENSEIGNEMENT BILINGUE 50% FRANÇAIS- 50% ANGLAIS',
            'top-header-image4': 'DÉCOUVREZ L’ÉCOLE MATERNELLE LE PAIN DE SUCRE À CAGNES-SUR-MER',
            'top-header-image5': 'DÉCOUVREZ L’ÉCOLE PRIMAIRE LE PAIN DE SUCRE À CAGNES-SUR-MER',
            'top-header-image6': 'INSCRIVEZ-VOUS À NOTRE CENTRE D’EXAMENS CAMBRIDGE ENGLISH À CAGNES-SUR-MER',
            'top-header-image7': 'Offrez un avenir international à vos enfants !',
            'top-header-image8': 'S’INSCRIRE À L’ÉCOLE INTERNATIONALE BILINGUE, LE PAIN DE SUCRE À CAGNES-SUR-MER',
            'top-header-image9': 'BABY-CLUB / MINI-CLUB / HAPPYLAND',
            'top-header-image10': 'VENIR À L’EIB DE CAGNES-SUR-MER',
            'top-header-image11': 'PROTECTION DE LA VIE PRIVÉE',
            'top-header-image12': 'RÈGLEMENT SCOLAIRE / VIE SCOLAIRE',

            // MINI-CLUB
            'mini-club-title': 'Le Mini-Club - Happyland',
            'mini-club-text1': 'Des mercredis et vacances scolaires heureux pour les enfants de 2 à 12 ans scolarisés à l\'EIB !',
            'mini-club-text2': 'Valeurs et projet éducatif',
            'mini-club-text3': 'A travers des activités aussi variées que ludiques en immersion linguistique, les enfants sont sensibilisés à la réalité internationale dans un environnement stimulant basé sur le respect et la tolérance pour les autres et l\'ouverture d\'esprit envers les différentes cultures.<br />Nous leur apportons un cadre familial et une équipe pédagogique soucieuse de leur bien être.',
            'mini-club-text4': 'Activités',
            'mini-club-text5': '<strong>Mini-club des mercredis :</strong> Chaque mois un nouveau thème est proposé, avec des activités ludiques de bricolage, d\'arts plastiques, sportives, culturelles, des jeux, des chants et des histoires. <br /><br /> <strong>Mini-club des vacances :</strong> Chaque semaine un nouveau thème est proposé avec des activités ludiques de bricolage, d\'arts plastiques, sportives, culturelles, des jeux, des chants et des histoires.',
            'mini-club-text6': 'Cuisine - Repas',
            'mini-club-text7': 'Notre cuisinier prépare des menus sains, équilibrés ou à thèmes. Les goûters sont aussi assurés.',
            'mini-club-text8': 'PROGRAMMES BABY-CLUB ET PROGRAMMES LOUSTICS',
            'mini-club-text9': 'Télécharger les programmes',

            // LE BILINGUISME
            'biling-title': 'Le Bilinguisme',
            'biling-text1': 'La réussite de nos élèves dépend aussi de leur capacité de communication dans les deux langues. Toutefois, notre rôle et notre mission d’Ecole Internationale Bilingue est aussi de permettre aux élèves d’aborder et de respecter les autres cultures.',
            'biling-text2': 'Le Programme Académique Français est renforcé et le niveau scolaire de l’établissement est élevé. Les horaires de français, mathématiques et histoire sont identiques à ceux des Ecoles Françaises. Le programme Anglais suit le « curriculum » proposé par les établissements anglais et s’appuie sur le même matériel pédagogique.',
            'biling-text3': 'Ainsi, dans un climat de culture bilingue, les enfants apprennent l’anglais et le français de façon naturelle, par une méthode dite d’imprégnation et avec des enseignants confirmés, anglophones et français. Le programme d’immersion linguistique est organisé dès la toute petite section (2 ans). La pratique de la langue anglaise est donc quotidienne et des groupes de niveau permettent un enseignement adapté à chaque élève.',
            'biling-text4': 'Enfants Anglophones, Francophones et Étrangers',
            'biling-text5': 'Les enfants n’ayant pas la pratique de la langue française ou anglaise sont acceptés. Nous assurons une pédagogie individualisée et des cours de mise à niveau peuvent être organisés, facilitant l’intégration des élèves et respectant le rythme de chacun.',
            'biling-text6': 'L’École Internationale Bilingue est ',
            'biling-text7': 'centre d’examen de Cambridge ',
            'biling-text8': 'et prépare aux diplômes suivants : starters – movers – flyers.',

            // VIE SCOLAIRE
            'vie-scolaire-title': 'Vie Scolaire',
            'vie-scolaire-t1': 'La scolarité s’étend de septembre à juin inclus.',
            'vie-scolaire-t3': 'LES HORAIRES DE L’ÉTABLISSEMENT',
            'vie-scolaire-t4': 'Du lundi au vendredi de 7h30 à 18h30. <br /><br/>Le mini-club accueille les enfants les mercredis et durant les vacances scolaires de Septembre à fin Juin, avec une semaine de fermeture à Noël.<br /><br/>En Juillet, vous pouvez faire participer votre enfant à nos activités et stages ludiques du 1er au 10 Juillet (option).',
            'vie-scolaire-t5': 'HORAIRES DES COURS',
            'vie-scolaire-t6': 'Classes maternelles',
            'vie-scolaire-t7': 'Lundi – Mardi – Jeudi – Vendredi',
            'vie-scolaire-t8bis': 'TPS : Les lapins (dès 2 ans) - 9h00 à 11h20 – 14h00 à 16h20',
            'vie-scolaire-t9': 'PS : Les écureuils (dès 2 ans) - 9h00 à 11h20 – 14h00 à 16h20',
            'vie-scolaire-t10': 'MS : Les pandas (4 ans) - 9h00 à 12h00 – 13h30 à 16h30',
            'vie-scolaire-t11': 'GS : Les koalas (5 ans) - 9h00 à 12h00 – 13h30 à 16h30',
            'vie-scolaire-t12': 'La sieste est assurée en fonction des besoins de chacun.',
            'vie-scolaire-t13': 'Classes primaires',
            'vie-scolaire-t14': 'Lundi – Mardi – Jeudi – Vendredi',
            'vie-scolaire-t15': 'Les CP et CE1 de 8h30 à 12h00 et de 13h30 à 16h40',
            'vie-scolaire-t16': 'Les CE2 – CM1 – CM2 de 8h30 à 12h30 et de 14h00 à 16h50',
            'vie-scolaire-t17': 'RESTAURANT SCOLAIRE',
            'vie-scolaire-t18': '4 services sont assurés.<br /><br />Les goûters sont fournis par l’école le matin et l’après-midi.<br /><br />Les menus sont affichés toutes les semaines et consultables sur le site internet de l’école dans la rubrique "Infos".<br /><br />Notre cuisinier assure une cuisine saine et variée et nous nous efforçons d’intégrer des produits frais et locaux.<br /><br />Les régimes particuliers sont pris en considération sur prescription médicale.',
            'vie-scolaire-t19': 'ETUDE DIRIGÉE',
            'vie-scolaire-t20': 'L’étude a lieu tous les soirs, à l’exception du mercredi et du dernier jour précédant les vacances scolaires.',
            'vie-scolaire-t21': 'CP - CE1 : de 17h10 à 17h40',
            'vie-scolaire-t22': 'CE2 - CM1- CM2 : de 17h10 à 18h00',
            'vie-scolaire-t27': 'ACCUEIL PERI-SCOLAIRE',
            'vie-scolaire-t23': 'Maternelles : Tous les jours de 7h30 à 9h00 et de 16h30 à 18h30.',
            'vie-scolaire-t24': 'Primaires : Tous les jours de 7h30 à 8h30 et après l\'étude de 17h45 à 18h30 (CP) et de 18h à 18h30 (CE1 - CE2 - CM1 - CM2).',
            'vie-scolaire-t25': 'BABY CLUB - MINI CLUB - HAPPYLAND',
            'vie-scolaire-t26': 'Le mini-club organise les activités et sorties du mercredi et des vacances scolaires pour les enfants scolarisés à l\'EIB.<br/> Activités proposées : Atelier informatique, arts plastiques, jeux collectifs, et suivant les âges :sorties, théâtre...<br/> Différents thèmes sont proposés à chaque vacances scolaires à l’exception des vacances de Noël.<br/><br/> <strong>Fermeture de l’établissement le 28 juin 2019.</strong>',
            'vie-scolaire-t30': 'BUS',
            'vie-scolaire-t31': 'Le bus permet à l’école d’organiser des sorties mais aussi d’assurer un ramassage scolaire de proximité.',
            'vie-scolaire-t32': 'SÉCURITÉ',
            'vie-scolaire-t33': 'La sécurité est aussi une priorité, l’école est sécurisée par une vidéo surveillance.<br />L’accès à l’école n’est pas accepté à l’exception de réunions, rendez-vous ou fêtes.<br />Un badge nominatif sera remis à chaque parent à la rentrée scolaire.',


            // CONTACT
            'contact-error-form': 'Merci de renseigner tout les champs requis',
            'contact-text': 'Le Pain de Sucre est un établissement d’enseignement <strong>privé déclaré et agréé par l’Education Nationale depuis 1982.</strong> La Directrice Fondatrice est Madame Rosfelder.',
            'contact-prise-rdv': 'Prise de rendez-vous par téléphone au',
            'contact-par-mail': ' ou par mail à ',
            'contact-plaholder1': 'Nom *',
            'contact-plaholder2': 'Prénom *',
            'contact-plaholder3': 'Téléphone *',
            'contact-plaholder4': 'E-mail *',
            'contact-plaholder5': 'Sélectionnez une réponse *',
            'contact-plaholder6': 'Inscription de votre enfant en maternelle',
            'contact-plaholder7': 'Inscription de votre enfant en primaire',
            'contact-plaholder8': 'Inscription au Cambridge',
            'contact-plaholder9': 'Demande de rendez-vous',
            'contact-plaholder10': 'Autre',
            'contact-plaholder11': 'Dites nous en davantage… *',
            'contact-policies': 'J’accepte la ',
            'contact-policies1': 'politique de protection de la vie privée du site *',
            'contact-send': 'Envoyer le formulaire',
            'contact-direction': 'La direction reçoit les lundis, mardis et jeudis uniquement l’après-midi, sur rendez-vous',


            // CAMBRIDGE
            'cambridge-title': 'Le Centre d\'examen Cambridge',
            'cambridge-text1': 'L’Ecole Internationale Bilingue Le Pain de Sucre est un Centre d’examens de Cambridge et prépare aux diplômes suivants : ',
            'cambridge-text2': 'Cambridge English est leader mondial pour les certifications en anglais.',
            'cambridge-text3': 'Les Cambridge English sont les certifications d’anglais les plus reconnues au monde. Elles sont proposées sur une échelle de six niveaux qui correspond au Cadre Européen commun de référence pour les langues. Les examens évaluent les quatre compétences linguistiques : compréhension orale, expression orale, compréhension écrite et expression écrite. Ils évaluent la capacité des candidats à utiliser les différentes structures et fonctions linguistiques en situation réelle.',
            'cambridge-text4': 'POURQUOI OBTENIR UN CERTIFICAT DE CAMBRIDGE ?',
            'cambridge-text5': 'Chaque année, plus de trois millions de personnes dans le monde se présentent à un examen de langue organisé par Cambridge ESOL. Ils le font dans le but d’obtenir une qualification reconnue internationalement qui validera leur niveau de compétence linguistique et les aidera dans leurs études ou leur profession. Les examens de Cambridge sont acceptés par un grand nombre d’établissements d’enseignement et de sociétés de tout type.',
            'cambridge-text6': 'POUR QUI ?',
            'cambridge-text7': 'Ces examens sont reconnus internationalement et sont indispensables à l’entrée en premier cycle dans une université au Royaume-Uni pour les non-anglophones. De plus, ces examens sont reconnus par la majorité des employeurs comme une référence.',
            'cambridge-text8': 'VALIDITÉ',
            'cambridge-text9': 'L’ensemble des examens de Cambridge sont valables à vie.',
            'cambridge-text10': 'FORMAT DE L’ÉPREUVE',
            'cambridge-text11': 'Le format est identique à l’ensemble des tests, seul le degré de difficulté et l’exigence de la maîtrise de l’anglais diffèrent. Chaque test est divisé en 5 parties : Compréhension écrite, expression écrite, maîtrise de l’anglais, compréhension orale, expression orale. La partie orale est une conversation avec un professeur.',


            // INSCRIPTION
            'inscription-title': 'Inscription',
            'inscription-text1': 'Vous souhaitez inscrire votre enfant en maternelle, ou en classe primaire au sein de notre Établissement d’enseignement privé déclaré et agréé par l’Education Nationale depuis 1982.',
            'inscription-text2': 'La Directrice, Madame Rosfelder, vous reçoit les lundis, mardis et jeudis uniquement l’après-midi, sur rendez-vous.',
            'inscription-text3': 'LE DOSSIER D’INSCRIPTION',
            'inscription-text4': 'Les inscriptions à notre école se font dès le mois de janvier précédent la rentrée. Bien entendu, des inscriptions en cours d’année sont possibles, dans la mesure des places disponibles.',
            'inscription-text5': 'Téléchargez le dossier d\'inscription',
            'inscription-text6': 'Téléchargez le dossier d\'inscription',
            'inscription-text7': 'Pour toute demande d\'inscription, prendre un rendez-vous avec la Direction',
            'inscription-text8': 'Rentrée 2021/2022- Inscriptions en cours d\'année  : Acceptées dans la mesure des places disponibles.',
            'inscription-text9': 'Rentrée scolaire 2022/2023 : Inscriptions à partir du mois de  janvier.',
            'inscription-text10': 'Pour toute demande d\'inscription, merci de nous rapporter le dossier complété ainsi que les pièces suivantes :',
            'inscription-text11': 'Livret de famille',
            'inscription-text12': 'Carnet de santé (vaccinations)',
            'inscription-text13': 'Certificat de radiation et dossier scolaire (Pour les classes de Primaire)',
            'inscription-text14': 'Photos d’identité (2)',
            'inscription-text15': 'Copie CNI des personnes autorisées à prendre l’enfant',
            'inscription-text16': 'Règlement complet des frais d’inscription',
            'inscription-text17': 'Relevé d’identité bancaire et autorisation de prélèvement',
            'inscription-text18': 'Justificatif de garde ou de garde alternée du parent',
            'inscription-text19': 'À la rentrée',
            'inscription-text20': 'Assurance Responsabilité civile + dommages corporels',
            'inscription-text21': 'Certificat médical d’aptitude à la pratique sportive',
            'inscription-text22': 'La Direction reçoit tous les jours sur rendez-vous.',
            'inscription-text23': 'Prise de rendez-vous par téléphone au 04 93 73 70 41',

            // PRIMAIRE
            'primaire-title': 'Le Primaire',
            'primaire-text1': 'Comme en maternelle, les effectifs sont limités afin de permettre un travail soutenu et individualisé ainsi que de nombreuses activités d’éveil.  Pour renforcer l\'enseignement, un tableau blanc interactif est installé dans chaque classe d\'anglais de la maternelle au CM2.',
            'primaire-text2': 'Projet Culturel 2022/2023',
            'primaire-text3': 'L\'architecture',
            'primaire-text4': 'Programme en Français',
            'primaire-text5': 'Lecture, technique et compréhension (méthode phonétique et syllabique)',
            'primaire-text6': 'Écriture',
            'primaire-text7': 'Grammaire',
            'primaire-text8': 'Conjugaison',
            'primaire-text9': 'Vocabulaire',
            'primaire-text10': 'Orthographe lexicale',
            'primaire-text11': 'Orthographe grammaticale',
            'primaire-text12': 'Expression écrite Poésie Histoire / découverte du monde',
            'primaire-text13': 'Mathématiques : raisonnement',
            'primaire-text14': 'Techniques opératoires',
            'primaire-text15': 'Géométrie',
            'primaire-text16': 'Programme en Anglais',
            'primaire-text17': 'Lecture',
            'primaire-text18': 'Grammaire',
            'primaire-text19': 'Orthographe',
            'primaire-text20': 'Expression orale',
            'primaire-text21': 'Expression écrite',
            'primaire-text22': 'Education civique / développement personnel',
            'primaire-text23': 'Sciences',
            'primaire-text24': 'Géographie',
            'primaire-text25': 'Éveil à l’art',
            'primaire-text26': 'Musique : Piano ou Violon',
            'primaire-text27': 'Notre professeur de musique assure l’éducation musicale dès la moyenne section avec un cours hebdomadaire de solfège. Possibilité de cours individualisé.',
            'primaire-text28': 'Sport',
            'primaire-text29': 'Notre professeur de sport assure toutes les semaines des activités. Gymnastique, athlétisme... Des sessions de natation ont lieu en septembre et au mois de mai et juin.',
            'primaire-text30': 'Informatique',
            'primaire-text31': 'À l’Ecole Internationale Bilingue, l’informatique est un outil pédagogique qui permet d’effectuer des recherches et de travailler en relation avec les programmes scolaires.',
            'primaire-text32': 'Un carnet de notes est remis à chaque fin de trimestre.',
            'primaire-text33': 'Le livret "Citoyen" - Santé Sécurité - Permis piéton',
            'primaire-text34': 'Il permet de développer : les notions de droits et devoirs, les notions de responsabilité et de liberté.',
            'primaire-text35': 'Horaires des cours',
            'primaire-text36': 'Lundi – Mardi – Jeudi – Vendredi',
            'primaire-text37': 'CP : De 8h30 à 12h30 et de 14h00 à 16h50',
            'primaire-text38': 'CE1 – CE2 – CM1 – CM2 : De 8h30 à 12h30 et de 14h00 à 16h50',
            'primaire-text39': 'ETUDE DIRIGÉE',
            'primaire-text40': 'L’étude a lieu tous les soirs, à l’exception du mercredi et du dernier jour précédant les vacances scolaires.',
            'primaire-text41': 'CP - CE1 : de 17h00 à 17h45',
            'primaire-text42': 'CE2 - CM1- CM2 : de 17h10 à 18h00',
            'primaire-text43': 'Inscription au primaire',
            'primaire-text44': 'Pour toute inscription, le dossier scolaire est requis.<br /> Des tests d’admission pourront être organisés à la demande de la direction.<br /> La décision de la direction sera alors notifiée aux parents.<br />',
            'primaire-text45': 'Télécharger le dossier d\'inscription',

            // MATERNELLE
            'maternelle-title': 'La Maternelle',
            'maternelle-text1': '<p>Nous sommes conscients que l’arrivée dans une nouvelle école est un&nbsp;moment important pour chaque enfant et ses parents.&nbsp;Dès l’inscription, nous mettons tout en œuvre pour une intégration&nbsp;réussie, adaptée à chaque enfant.</p> <p>L’équipe pédagogique est composée d’enseignants de différentes nationalités, diplômés et possédant tous une sérieuse expérience pédagogique. Elle entretient une relation ouverte et positive avec les familles.</p>',
            'maternelle-text2': 'Prenons rendez-vous',
            'maternelle-text3': 'Projet Culturel de classe 2022/2023',
            'maternelle-text4': 'L\'art',
            'maternelle-text5': 'Les «Tout Petits» dès l’âge de 2 ans vont découvrir, à leur rythme, le plaisir de s’amuser, de partager et de grandir ensemble.<br /><br /> Leur univers est adapté et des ateliers ou coins de jeux sont proposés sans oublier les activités d’éveil, la musique et la motricité.<br /><br /> Bien entendu, nous acceptons les doudous, sucettes et couches.',
            'maternelle-text6': 'Baby Éveil - Classes Maternelles',
            'maternelle-text7': '<p><strong>Dès l’âge de 2 ans</strong>, nous accueillons les enfants dans une section adaptée à leurs éveils et à leurs rythmes.</p> <p>Nous offrons à chaque enfant une ambiance chaleureuse et bienveillante, une qualité de vie tout en favorisant l’éveil et l’épanouissement des dispositions intellectuelles, sensorielles et affectives.</p> <p>Les classes sont gaies, colorées, avec des « coins jeux ou manipulation », un matériel varié, spécialisé, issu de différentes pédagogies actives. Toutes les classes d’anglais sont équipées d’un TBI.</p> <p><strong>Dès 4 ans : Méthode de lecture phonétique en Français et en Anglais ! </strong>À travers des thèmes transdisciplinaires les enseignants français et anglais proposent chaque mois un thème et assurent les activités suivantes.</p> <p>L’ emploi du temps, organisé sous forme d’<strong>ateliers « satellites » de 3 / 5 enfants</strong>, permet une grande autonomie et un travail individualisé.&nbsp;<strong>L’évaluation des enfants</strong>. À la fin de chaque période, vous recevez le dossier du travail de vos enfants. Tous les trimestres vous sera remis aussi son dossier de réussite / évaluation.</p>',
            'maternelle-text8': 'La petite section & ses ateliers',
            'maternelle-text9': 'Vocabulaire, langage',
            'maternelle-text10': 'Graphisme',
            'maternelle-text11': 'Pré-mathématiques – Logique',
            'maternelle-text12': 'Activités sensorielles',
            'maternelle-text13': 'Eveil artistique et musical',
            'maternelle-text14': 'Psychomotricité – expression corporelle',
            'maternelle-text15': 'La moyenne et grande section',
            'maternelle-text16': 'Approche de la lecture et de l’écriture',
            'maternelle-text17': 'Mathématiques : Méthode de Singapour',
            'maternelle-text18': 'Découverte du monde',
            'maternelle-text19': 'Éveil technologique et scientifique',
            'maternelle-text20': 'Éducation artistique et musicales',
            'maternelle-text21': 'Activités sportives (gymnastique…)',
            'maternelle-text22': 'Horaires des cours',
            'maternelle-text23': 'Classe de maternelle',
            'maternelle-text24': 'Lundi – Mardi – Jeudi – Vendredi',
            'maternelle-text25': 'TPS : Les lapins (2 ans) - De 9h00 à 11h20 et de 14h00 à 16h30',
            'maternelle-text26': 'PS : Les écureuils (3 ans) - De 9h00 à 11h20 et de 14h00 à 16h30',
            'maternelle-text27': 'MS : Les pandas (4 ans) - De 9h00 à 12h00 et de 13h30 à 16h30',
            'maternelle-text28': 'GS : Les koalas (5 ans) - De 9h00 à 12h00 et de 13h30 à 16h30',
            'maternelle-text29': 'Inscription en maternelle',
            'maternelle-text30': 'Pour toute inscription, le dossier scolaire est requis.<br /> Des tests d’admission pourront être organisés à la demande de la direction.<br /> La décision de la direction sera alors notifiée aux parents.<br />',
            'maternelle-text31': 'Télécharger le dossier d\'inscription',
            'maternelle-text32': 'L\'éveil musical et sportif',
            'maternelle-text33': '<p><strong>Motricité et sport</strong></p> <p>Les plus petits auront quotidiennement des activités motrices ou éveil corporel. À compter de la moyenne section un professeur de sport assurera les cours de sport collectif et gymnastique.</p> <p><strong>La musique</strong></p> <p>2/3 ans : Percussion tous les jours en anglais avec leur maîtresse anglophone et éveil musical avec notre professeure de musique italienne.<br /> 4/5 ans : cours de musique, chant et piano en Italien avec notre professeur de musique.</p>',

            // NOS VALEURS
            'nos-valeurs-title': 'Nos Valeurs',
            'nos-valeurs-text1': 'Notre équipe pédagogique, composée d’enseignants qualifiés, très motivés et de différentes nationalités, propose un projet éducatif et pédagogique qui s’appuie sur les principes suivants :',
            'nos-valeurs-list1': 'Un cursus bilingue et pluridisciplinaire avec suivi renforcé des programmes nationaux français et anglais',
            'nos-valeurs-list2': 'Une pédagogie différenciée et active respectant l’enfant et basée sur son autonomie',
            'nos-valeurs-list3': 'Une méthode d\'enseignement dite "classique"',
            'nos-valeurs-list4': 'Des activités motrices ou sportives',
            'nos-valeurs-list5': 'Une initiation musicale dès 4 ans',
            'nos-valeurs-list6': 'Des sorties pédagogiques',
            'nos-valeurs-list7': 'Des activités périscolaires (en option : judo, chinois, danse, piano …)',
            'nos-valeurs-list8': 'Une ouverture de l\'établissement de 7h30 à 18h30 (Ecole et Mini-club)',
            'nos-valeurs-list9': 'Un environnement sain et sécurisant',
            'nos-valeurs-text2': 'La pédagogie des ÉCOLES INTERNATIONALES BILINGUES est fondée sur les principes suivants :',
            'nos-valeurs-text3': "<strong>APPRENDRE AUX ENFANTS A SAVOIR FAIRE</strong><br />" +
                "Effectuer des recherches de manière intelligente et créative. Penser par eux-mêmes.<br />" +
                "Savoir faire preuve d’initiative en orientant leur réflexion, en prenant des décisions judicieuses permettant de résoudre des problèmes complexes." +
                "<br />" +
                "Communiquer dans plus d’une langue et respecter les valeurs des autres élèves et leurs cultures." +
                "<br />" +
                "Prendre des « risques » en abordant sans appréhension des situations nouvelles." +
                "<br />" +
                "Nous encourageons les enfants à réfléchir, à faire des choix et à entreprendre des actions afin de posséder des connaissances.",
            'nos-valeurs-text4': 'Développer le savoir être et les qualités suivantes :',
            'nos-valeurs-list10': 'La tolérance',
            'nos-valeurs-list11': 'Le respect de soi-même et des autres',
            'nos-valeurs-list12': 'L’empathie',
            'nos-valeurs-list13': 'La coopération',
            'nos-valeurs-list14': 'L’intégrité',
            'nos-valeurs-list15': 'La responsabilité',
            'nos-valeurs-list16': 'La confiance en soi',
            'nos-valeurs-list17': 'La curiosité',
            'nos-valeurs-list18': 'L’enthousiasme',
            'nos-valeurs-list19': 'L’autonomie',

            // ECOLE
            'ecole-text': "Dans la lignée des EIB Nice, Pégomas et Angers, notre école accueille, dans des classes à petits effectifs, des enfants de toutes nationalités, générant un nouveau “regard” sur l’enseignement en proposant un réel bilinguisme <strong>50% anglais, 50% français.</strong>" +
                "<br /><br />" +
                "Nous assurons toutes les sections de <strong>MATERNELLE (dès 2 ans)</strong> ainsi que les classes de <strong>PRIMAIRE</strong> du cours préparatoire au cours moyen 2." +
                "<br /><br />" +
                "Dans un climat chaleureux de dialogue et de respect d’autrui, fondé sur la confiance, chaque enfant s’épanouit scolairement, socialement et nous le préparons à vivre en société et à agir en lui donnant les outils fondamentaux et la liberté d’explorer et de créer en tenant compte de ses possibilités, sa maturité, ses capacités d’expression,son rythme biologique, son environnement familial et social.",


            // INDEX
            'home-top-title': 'Offrez un avenir international à vos enfants',
            'home-top-subtitle': 'Découvrez nos écoles internationales bilingues 50% Français / 50% Anglais',
            'home-text-1': `<span>Dans la lignée des</span> EIB Nice et Pégomas <span>notre école accueille, dans des classes à petits effectifs, des</span> enfants de toutes nationalités, <span>générant un nouveau “regard” sur l’enseignement en proposant un réel bilinguisme</span> 50% anglais, 50% français, <span>dès l’âge de 2 ans. Nous assurons toutes les sections de</span> maternelle <span>(dès 2 ans) ainsi que les classes de</span> primaire.`,
            "earth-1": "École bilingue depuis 1982",
            "earth-2": "Immersion linguistique Dès 2 ans",
            "earth-3": "Ouverture sur le monde",
            "earth-4": "Éveil à la culture",
            "earth-5": "Activités sportives, musicales et artistiques",
            'eib-difference': 'La différence EIB',
            'eib-diff-text': "Dans un climat chaleureux de dialogue et de respect d’autrui, fondé sur la confiance, chaque enfant s’épanouit scolairement, socialement et nous le préparons à vivre en société et à agir en lui donnant les outils fondamentaux et la liberté d’explorer et de créer en tenant compte de ses possibilités, sa maturité, ses capacités d’expression, son rythme biologique, son environnement familial et social.",
            'eib-mos-1': 'Éducation bilingue depuis 1982',
            'eib-mos-2': 'Ouverture sur le monde',
            'eib-mos-3': 'Pédagogie active et rigoureuse',
            'eib-mos-4': 'Dialogue, respect et confiance',
            'eib-mos-5': 'Activités culturelles, artistiques et sportives',
            'eib-mos-6': 'Baby Club dès 2ans, Maternelle et primaire',
            'directrice-title': 'LE MOT DE LA DIRECTRICE FONDATRICE …',
            'directrice-text1': 'Bienvenue dans nos Écoles Internationales Bilingues qui accueillent 950 élèves dans nos établissements suivants :',
            'directrice-text2': 'Depuis 35 ans nos Écoles Internationales offrent un cursus bilingue avec l’assurance d’un enseignement de grande qualité. Notre bilinguisme permet de suivre les programmes académiques Français ainsi que le curriculum anglais et, dès l’âge de 2 ans. Nos enseignants de langue maternelle leur proposent 50% en français et 50% en anglais.',
            'directrice-text3': 'À l’EIB chaque enfant est encouragé à s’exprimer et à participer activement à travers un enseignement pluridisciplinaire et des activités scolaires, culturelles, artistiques et sportives. Dans un climat de dialogue et de respect d’autrui, nous incitons les élèves à réfléchir, à mettre en place des stratégies, à se fixer des objectifs et à développer une confiance en eux tout en leur offrant un environnement culturel international stimulant et motivant. À l’EIB nous préparons nos élèves au monde de demain et nous leur donnons les outils fondamentaux pour grandir dans une dimension internationale basée sur le respect et la responsabilité.',

            // Footer
            'footer-une-question': 'Une question ?',
            'footer-notre-ecole-en-video': 'Notre école en vidéo',
            'footer-telecharger-dossier': 'Téléchargez le dossier d’inscription',
            'footer-icon-text1': 'Un enseignement de qualité',
            'footer-icon-text2': 'Un solide programme scolaire',
            'footer-icon-text3': 'Une école tournée vers le monde',
            'footer-icon-text4': 'Des cours dans les deux langues',
            'footer-demande-renseignement': 'Demande de renseignement',
            'footer-actu-facebook': 'Retrouvez l’actualité de notre école sur Facebook',
            'footer-suivi-insta': 'Suivez-nous sur instagram',
            'footer-ecole-maternelle': 'École maternelle',
            'footer-ecole-primaire': 'École primaire',
            'footer-dossier-inscription': 'Dossier inscription',
            'footer-protection': 'Protection de la vie privée',
            // ----- END Footer


        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;